
import * as Components from "./Kokorollramen"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "kokorollpa"
}

